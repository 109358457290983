<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <div>
        <div>
          <h1 class="mr-sm-4 header-tablepage">User detail</h1>
        </div>
        <div class="title-tabs mt-3">User Info</div>

        <b-container class="no-gutters bg-white">
          <div class="py-3">
            <b-row>
              <b-col lg="6" md="6" sm="12" xs="12">
                <InputText
                  textFloat="First Name"
                  placeholder="First Name"
                  type="text"
                  name="first_name"
                  isRequired
                  :isValidate="$v.form.first_name.$error"
                  :v="$v.form.first_name"
                  v-model="form.first_name"
                />
              </b-col>
              <b-col lg="6" md="6" sm="12" xs="12">
                <InputText
                  textFloat="Last Name"
                  placeholder="Last Name"
                  type="text"
                  name="last_name"
                  isRequired
                  :isValidate="$v.form.last_name.$error"
                  :v="$v.form.last_name"
                  v-model="form.last_name"
                />
              </b-col>
              <b-col lg="6" md="6" sm="12" xs="12">
                <InputText
                  textFloat="First Name (EN)"
                  placeholder="First Name (EN)"
                  type="text"
                  name="first_name_en"
                  isRequired
                  :isValidate="$v.form.first_name_en.$error"
                  :v="$v.form.first_name_en"
                  v-model="form.first_name_en"
                />
              </b-col>
              <b-col lg="6" md="6" sm="12" xs="12">
                <InputText
                  textFloat="Last Name (EN)"
                  placeholder="Last Name (EN)"
                  type="text"
                  name="last_name"
                  isRequired
                  :isValidate="$v.form.last_name_en.$error"
                  :v="$v.form.last_name_en"
                  v-model="form.last_name_en"
                />
              </b-col>
              <b-col
                lg="6"
                md="6"
                sm="12"
                xs="12"
                class="d-flex flex-column justify-content-between"
              >
                <div>
                  <div class="d-flex align-items-center">
                    <span class="label">
                      Email
                      <span class="text-error" v-if="form.is_admin">*</span>
                    </span>
                    <span
                      class="ml-auto reset-password"
                      @click="modalShow"
                      v-if="mode"
                    >
                      Reset Password
                    </span>
                  </div>
                  <div style="flex: 1">
                    <InputText
                      textFloat=""
                      placeholder="Email"
                      type="text"
                      name="email"
                      :isValidate="$v.form.email.$error"
                      :v="$v.form.email"
                      v-model="form.email"
                    />
                  </div>
                </div>
              </b-col>
              <b-col lg="6" md="6" sm="12" xs="12" v-if="!mode">
                <InputText
                  textFloat=""
                  placeholder="Password"
                  type="password"
                  name="password"
                  isRequired
                  customLabel
                  autocomplete="new-password"
                  :v="$v.form.password"
                  :isValidate="$v.form.password.$error"
                  v-model="form.password"
                  :isShowPassword="true"
                >
                  <template v-slot:textFloat>
                    <div class="content-between" style="margin-bottom: 5px">
                      <label class="mb-0"
                        >Password
                        <span class="text-error" v-if="form.is_admin"
                          >*</span
                        ></label
                      >
                      <b-button
                        size="xs"
                        class="ml-3"
                        @click="generatePassword()"
                        >Generate Password</b-button
                      >
                    </div>
                  </template>
                </InputText>
              </b-col>
            </b-row>
          </div>
        </b-container>

        <b-card no-body class="mb-1 border-none mt-3">
          <b-card-header header-tag="header" class="p-0 title-tabs" role="tab">
            <b-button
              block
              v-b-toggle.admin-tabs
              variant="custom-secondary"
              class="d-flex justify-content-start title-tabs"
            >
              <b-form-checkbox
                v-model="form.is_admin"
                disabled
                class="disabled-color"
              ></b-form-checkbox>

              <!-- disabled -->
              Permission Admin
            </b-button>
          </b-card-header>
          <b-collapse
            id="admin-tabs"
            accordion="admin-tabs"
            role="tabpanel"
            v-model="form.is_admin"
          >
            <b-card-body>
              <b-card-text>
                <b-row>
                  <b-col lg="6" md="6" sm="12" xs="12">
                    <div class="d-flex align-items-center">
                      <span class="label">
                        Role <span class="text-error">*</span></span
                      >
                      <span
                        class="ml-auto reset-password"
                        @click="modalRoleShow"
                        v-if="form.roleId"
                        >See More</span
                      >
                    </div>

                    <InputSelect
                      title=""
                      name="Role"
                      valueField="id"
                      v-model="form.admin_role_id"
                      :options="optionsRole"
                      textField="name"
                      :v="$v.form.admin_role_id"
                    >
                      <template v-slot:option-first>
                        <b-form-select-option value="" disabled>
                          -- Please Select Role --
                        </b-form-select-option>
                      </template>
                    </InputSelect>
                  </b-col></b-row
                >
              </b-card-text>
            </b-card-body>
          </b-collapse>
        </b-card>
        <b-card no-body class="mb-1 border-none mt-3">
          <b-card-header header-tag="header" class="p-0 title-tabs" role="tab">
            <b-button
              block
              v-b-toggle.staff-tabs
              variant="custom-secondary"
              class="d-flex justify-content-start title-tabs"
            >
              <b-form-checkbox
                v-model="form.is_sale"
                disabled
                class="disabled-color"
              ></b-form-checkbox>
              Permission Staff
            </b-button>
          </b-card-header>
          <b-collapse
            id="staff-tabs"
            accordion="staff-tabs"
            role="tabpanel"
            v-model="form.is_sale"
          >
            <b-card-body>
              <b-card-text>
                <b-row
                  ><b-col cols="12" md="6">
                    <InputText
                      id="id-of-staff"
                      textFloat="Staff ID."
                      placeholder="Staff ID."
                      type="text"
                      name="id-of-staff"
                      isRequired
                      :isValidate="$v.form.sales_no.$error"
                      :v="$v.form.sales_no"
                      v-model="form.sales_no"
                      autocomplete="new-password"
                  /></b-col>
                  <b-col cols="12" lg="6" class="d-flex">
                    <InputText
                      id="staff-pin-id"
                      textFloat="PIN (6-9 Digit)"
                      placeholder="Enter Your PIN"
                      class="flex-grow-1"
                      type="password"
                      maxLength="6"
                      name="staff-pin-id"
                      :isShowPassword="true"
                      v-on:paste.native="handlePastePin"
                      @onKeypress="allowOnlyNumbers($event)"
                      isRequired
                      :isValidate="$v.form.pin.$error"
                      :v="$v.form.pin"
                      v-model="form.pin"
                      autocomplete="new-password"
                    />
                    <p
                      class="text-custom-primary text-link t ml-2"
                      @click="openModalPin()"
                    >
                      Generate
                    </p>
                    <div class="ml-2">
                      <div class="text-custom-primary">
                        เงื่อนไขการตั้งรหัส PIN
                      </div>
                      <div>1. ห้ามเรียงเลข เช่น 1234</div>
                      <div>
                        2. ห้ามใช้เลขเดียวซ้ำและติดกันเกิน 1 คู่ เช่น 112217
                      </div>
                      <div>3. ห้ามเลขซ้ำกันเกิน 3 ตัว</div>
                      <div>4. ห้ามซ้ำกับคนอื่น</div>
                    </div>
                  </b-col>
                </b-row>
                <div class="title-tabs mt-3">Branch</div>

                <b-container class="no-gutters bg-white">
                  <div class="pt-3">
                    <b-row class="justify-content-between align-items-center">
                      <b-col lg="5" md="6" sm="6" xs="10" cols="10">
                        <b-input-group>
                          <b-form-input
                            id="search-branch"
                            name="search-branch"
                            class="search-bar"
                            @keyup.enter="handleSearch($event)"
                            @input="handleSearch($event)"
                            placeholder="Search Branch, Plant ID"
                            v-model="filter.search"
                            autocomplete="false"
                          >
                          </b-form-input>
                          <b-input-group-append is-text>
                            <b-iconstack font-scale="2" type="submit">
                              <b-icon
                                stacked
                                icon="search"
                                scale="0.5"
                                variant="grey"
                              ></b-icon>
                            </b-iconstack>
                          </b-input-group-append> </b-input-group
                      ></b-col>
                      <b-col
                        xl="2"
                        lg="3"
                        md="3"
                        sm="6"
                        xs="2"
                        cols="2"
                        class="d-flex justify-content-end"
                      >
                        <button
                          type="button"
                          class="btn button btn-mobile btn-block"
                          id="open-modal-branch"
                          @click="openModalBranch()"
                        >
                          <font-awesome-icon
                            icon="plus"
                            class="text-black d-sm-none"
                          />
                          <span class="d-none d-sm-block">
                            <font-awesome-icon
                              icon="plus-square"
                              class="pointer"
                            />
                            Add Branch
                          </span>
                        </button>
                      </b-col>
                    </b-row>
                    <div class="pb-3 mt-3">
                      <TableStaff
                        :filter="filter"
                        :pageOptions="pageOptions"
                        :fields="fields"
                        :items="showBranch"
                        :isBusy="isBusy"
                        :rows="rows"
                        :showingTo="showingTo"
                        @filterPage="filterPage"
                        @deleteBranch="deleteBranch"
                        :validate="branchRequired"
                      />
                    </div>
                  </div>
                </b-container>
              </b-card-text>
            </b-card-body>
          </b-collapse>
        </b-card>
        <div class="no-gutters bg-white mt-3 py-2 px-3">
          <b-form-checkbox
            switch
            v-model="form.active"
            class="radio-active"
            size="lg"
            :value="1"
            :unchecked-value="0"
          >
            <span class="ml-2 main-label">{{
              form.active ? "Active" : "Inactive"
            }}</span>
          </b-form-checkbox>
        </div>
        <FooterAction @submit="saveForm()" routePath="/user-permission" />
      </div>
      <ModalLoading ref="modalLoading" />
      <ModalChangePassword ref="modalChangePassword" @submit="changePassword" />
      <ModalDisplayRole
        ref="modalDisplayRole"
        :roleID="form.roleId"
        v-if="form.roleId"
      />
    </div>
    <ModalSelectBranch
      ref="ModalSelectBranch"
      @selectBranch="selectBranch"
      :list="branchList"
      id="1"
    />
    <ModalRandomPin ref="ModalRandomPin"> </ModalRandomPin>
  </div>
</template>

<script>
import {
  required,
  email,
  minLength,
  maxLength,
  requiredIf,
} from "vuelidate/lib/validators";
import InputText from "@/components/inputs/InputText";
import InputSelect from "@/components/inputs/InputSelect";
import OtherLoading from "@/components/loading/OtherLoading";
import ModalChangePassword from "@/components/modal/ModalChangePassword";
import ModalDisplayRole from "@/components/admin/ModalDisplayRole";
import ModalLoading from "@/components/modal/ModalLoading";
import TableStaff from "@/components/staff/TableStaff";
import ModalSelectBranch from "@/components/staff/ModalSelectBranch";
import ModalRandomPin from "./components/ModalRandomPin";

const eng_number = (id) => {
  if (id) {
    if (id.match("[a-zA-Z\s0-9-_#{}]+$")) {
      return true;
    }
    if (thai_only(id)) {
      return false;
    }
  }
  return true;
};
const thai_only = (val) => {
  if (val) {
    if (val.match("[ก-๏\s]+$")) {
      return true;
    }
  }
  return false;
};
export default {
  name: "Detail",
  components: {
    InputText,
    InputSelect,
    OtherLoading,
    ModalChangePassword,
    ModalLoading,
    ModalDisplayRole,
    TableStaff,
    ModalSelectBranch,
    ModalRandomPin,
  },
  validations() {
    var form = {
      first_name: { required },
      last_name: { required },
      first_name_en: { required, eng_number },
      last_name_en: { required, eng_number },
      email: {
        required: requiredIf(() => {
          return this.form.is_admin;
        }),

        email,
      },

      password: {
        required: requiredIf(() => {
          return this.form.is_admin;
        }),

        minLength: minLength(6),
      },
      admin_role_id: {
        required: requiredIf(() => {
          return this.form.is_admin;
        }),
      },
      sales_no: {
        required: requiredIf(() => {
          return this.form.is_sale;
        }),
      },
      pin: {
        required: requiredIf(() => {
          return this.form.is_sale;
        }),
        minLength: minLength(6),
        maxLength: maxLength(6),
      },
    };
    if (this.mode) {
      form.password = {};
    }
    return {
      form,
    };
  },

  computed: {
    mode() {
      return this.$route.params.id != 0 ? true : false;
    },
  },
  watch: {
    "form.is_sale"(val) {
      if (!val) {
        this.form.sales_no = "";
        this.form.pin = "";
        this.items = [];
        this.showBranch = [];
        for (let branch of this.branchList) {
          branch.selected_count = 0;
          for (const check of branch.branchList) {
            // if (this.form.branch_id.includes(check.id)) {
            branch.selected_count = 0;
            check.branch_group = branch.name;
            check.is_check = false;
            // branchSelect.push(check);
            // }
          }
        }
        // this.rows = 0;
      }
    },
    "form.is_admin"(val) {
      if (!val) {
        this.form.admin_role_id = "";
        // this.form.active = 0;
      }
    },
  },
  data() {
    return {
      isLoading: false,
      form: {
        email: "",
        first_name: "",
        last_name: "",
        first_name_en: "",
        last_name_en: "",
        active: 1,
        admin_role_id: "",
        sales_no: "",
        add_branch_id: [],
        password: "",
        clone_branch: [],
        branch_id: [],
        is_admin: true,
        is_sale: true,
        pin: "",
      },
      pageOptions: [
        { value: 5, text: "5 / page" },
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],

      optionsRole: [],
      id: this.$route.params.id,
      filter: {
        search: "",
        take: 5,
        page: 1,
      },
      showBranch: [],
      branchList: [],
      fields: [
        {
          key: "plant_id",
          label: "Plant ID",
        },
        {
          key: "name",
          label: "Branch Name",
        },
        {
          key: "branch_group",
          label: "Branch Group",
        },

        {
          key: "branch_type",
          label: "Type",
        },
        {
          key: "actions",
          label: "Action",
        },
      ],
      isBusy: false,
      rows: 0,
      showing: 1,
      showingTo: 10,
      items: [],
      delete_branch_id: [],
      branchRequired: false,
      oldPin: "",
    };
  },
  async created() {
    this.$bus.$emit("showLoading");
    if (this.mode) {
      await this.getAdminByID();
    }
    await this.getRole();
    await this.getBranchList();
    this.$bus.$emit("hideLoading");
  },
  destroyed() {},
  methods: {
    modalShow() {
      this.$refs.modalChangePassword.show();
    },
    modalRoleShow() {
      this.$refs.modalDisplayRole.show();
    },
    async getAdminByID() {
      const { data } = await this.axios(
        `/user/getUserByUserGuid/${this.$route.params.id}`
      );
      this.form = data.detail;

      this.oldPin = this.form.pin;

      this.form.clone_branch = this.form.branch_id || [];
      this.form.branch_id = this.form.branch_id || [];
      this.form.is_admin = this.form.is_admin == 1 ? true : false;
      this.form.is_sale = this.form.is_sale == 1 ? true : false;
      this.form.delete_branch_id = [];
      this.form.add_branch_id = [];
    },
    async getRole() {
      const { data } = await this.axios(`/Admin/getFilterList`);
      this.optionsRole = data.detail;
    },
    async saveForm() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      if (this.form.is_sale) {
        if (this.oldPin !== this.form.pin) {
          const checkPin = await this.axios(
            `/user/validatePin/${this.form.pin}`
          );
          if (!checkPin.data.detail) {
            this.errorAlert(checkPin.data.message);
            return;
          }
        }
      }
      if (!this.form.is_admin && !this.form.is_sale)
        return this.warningAlert("Please Select User Permission.");

      let payload = JSON.parse(JSON.stringify(this.form));
      if (payload.admin_role_id == "") payload.admin_role_id = 0;
      if (this.form.is_sale == 1 && this.showBranch.length == 0)
        return (this.branchRequired = true);
      if (this.form.is_sale == 0)
        payload.delete_branch_id = payload.clone_branch;
      payload.is_admin = payload.is_admin ? 1 : 0;
      payload.is_sale = payload.is_sale ? 1 : 0;
      payload.add_branch_id = this.form.add_branch_id.filter(
        (el) => !this.form.clone_branch.includes(el)
      );
      this.$refs.modalLoading.show();

      if (this.mode) {
        this.axios
          .put(`/user/update/user`, payload)
          .then((res) => {
            if (res.data.result) {
              this.successAlert().then(() =>
                this.$router.push("/user-permission")
              );
            } else {
              this.errorAlert(res.data.message);
            }
            this.$refs.modalLoading.hide();
          })
          .catch((err) => {
            this.$swal2.fire(
              "An Error Occurred",
              "An error occurred, the data could not be saved. Please try again.",
              "error"
            );
          });
      } else {
        this.axios
          .post(`/user/create/user`, payload)
          .then((res) => {
            if (res.data.result) {
              this.successAlert().then(() =>
                this.$router.push("/user-permission")
              );
            } else {
              // this.form.is_admin = this.form.is_admin == 1 ? true : false;
              // this.form.is_sale = this.form.is_sale == 1 ? true : false;
              this.errorAlert(res.data.message);
            }
            this.$refs.modalLoading.hide();
          })
          .catch((err) => {
            this.errorAlert(
              "An Error Occurred",
              "An error occurred, the data could not be saved. Please try again."
            );
          });
      }
    },
    generatePassword() {
      var chars =
        "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      var passwordLength = 20;
      var password = "";
      for (var i = 0; i <= passwordLength; i++) {
        var randomNumber = Math.floor(Math.random() * chars.length);
        password += chars.substring(randomNumber, randomNumber + 1);
      }

      this.form.password = password;
    },
    changePassword(val) {
      this.form.password = val;
      this.saveForm();
    },
    handleSearch(val) {
      var keys = ["plant_id", "name"];
      if (val) {
        var result = this.items.filter(function (e) {
          return keys.some(function (a) {
            return e[a].toLowerCase().includes(val.toLowerCase());
          });
        });
        this.showBranch = result.length == 0 && val == "" ? this.items : result;
      } else {
        this.showBranch = this.items;
      }
    },
    selectBranch(val) {
      var branchSelect = [];
      for (let branchs of this.branchList) {
        for (let branch of branchs.branchList) {
          if (val.includes(branch.id)) {
            branch.is_check = true;
            branch.branch_group = branchs.name;
            branchSelect.push(branch);
          } else {
            branch.is_check = false;
          }
        }
      }
      var index = 0;
      for (let deleteValue of this.delete_branch_id) {
        if (val.includes(deleteValue)) this.delete_branch_id.splice(index, 1);
        index++;
      }
      const add = val.filter((el) => !this.form.add_branch_id.includes(el));

      const deleteID = this.form.clone_branch.filter((i) => !val.includes(i));

      this.items = JSON.parse(JSON.stringify(branchSelect));
      this.showBranch = JSON.parse(JSON.stringify(branchSelect));
      this.form.add_branch_id = add;
      this.form.delete_branch_id = deleteID;
      this.rows = val.length;
      this.branchRequired = false;
    },
    async getBranchList() {
      if (!this.form.is_sale) return;
      const result = await this.axios(`/branch/group`);
      var branchSelect = [];
      for (let branch of result.data.detail) {
        branch.selected_count = 0;
        for (const check of branch.branchList) {
          if (this.form.branch_id.includes(check.id)) {
            branch.selected_count += 1;
            check.branch_group = branch.name;
            check.is_check = true;
            branchSelect.push(check);
          }
        }
      }

      this.rows = branchSelect.length;
      this.branchList = result.data.detail;
      this.items = JSON.parse(JSON.stringify(branchSelect));
      this.showBranch = JSON.parse(JSON.stringify(branchSelect));
      this.isLoading = false;
    },
    deleteBranch(id) {
      if (!this.delete_branch_id.includes(id)) {
        const index = this.showBranch.findIndex((el) => el.id == id);
        const indexItems = this.items.findIndex((el) => el.id == id);

        const brachCheck = this.branchList.find((el) =>
          el.branchList.find((el) => {
            return el.id == id;
          })
        );

        const branch = brachCheck.branchList.find((el) => el.id == id);
        branch.is_check = false;
        brachCheck.selected_count -= 1;
        this.showBranch.splice(index, 1);
        this.items.splice(indexItems, 1);

        if (this.form.add_branch_id) {
          const addValue = this.form.add_branch_id.indexOf(id);
          if (addValue != -1) this.form.add_branch_id.splice(addValue, 1);
        }

        if (this.form.clone_branch.includes(id)) {
          this.form.delete_branch_id.push(id);
        }

        this.rows = this.items.length;
      }
    },
    filterPage(filter) {
      this.filter = filter;
    },
    async openModalBranch() {
      this.$bus.$emit("showLoading");
      if (this.branchList.length == 0) await this.getBranchList();

      this.$bus.$emit("hideLoading");
      this.$refs.ModalSelectBranch.show();
    },
    openModalPin() {
      this.$refs.ModalRandomPin.show();
    },
    allowOnlyNumbers(event) {
      const keyCode = event.keyCode || event.which;
      if (keyCode < 48 || keyCode > 57) {
        event.preventDefault();
      }
    },
    handlePastePin(event) {
      const pastedText = event.clipboardData.getData("text");
      if (!/^\d+$/.test(pastedText)) {
        event.preventDefault();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.img-box-preview {
  width: 100%;
  padding-bottom: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  border: 2px dashed #bfbfbf;
  position: relative;
}
.reset-password {
  text-decoration: underline;
  color: blue;
  cursor: pointer;
  font-size: 12px;
}
::v-deep h2#swal2-title.swal2-title {
  font-size: 24px !important;
}

.text-link {
  cursor: pointer;
  text-decoration: underline;
  margin-top: 30px;
}
</style>
